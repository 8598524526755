import React, { useCallback } from 'react'
import { Button, Card, FormLayout } from "@shopify/polaris";
import update from 'immutability-helper'
import Conditions from './common/conditions/conditions'
function SmartRules(props) {
    const { host, shop, token, addSmartRule, state, setState, productPickerShow, setProductPickerShow,currencySymbol,type, existingOffer, factsOptionsValue,customerOperatorOptionsValue } = props
    const normalizeShopifyID = (id) => {
            return Number(
              String(id)
                .replace('gid://shopify/ProductVariant/', '')
                .replace('gid://shopify/Product/', '')
            );
    }   
    const handleSwapChange = (newChecked) => { setState(update(state,{swap:{$set: newChecked}})) }
    const handleSwapQuantityChange = (newChecked) => { setState(update(state,{swapQuantity:{$set: newChecked}})) }
    const handleAutoAddChange = (newChecked) => { setState(update(state,{autoAdd:{$set: newChecked}})) }

    const handleDiscountCodeChange = (newValue) => { setState(update(state, {
        discountCode: {
            value: {
                $set: newValue
            }
        }
    }))}

    const handleDiscountCodeStatusChange = (newChecked) => { setState(update(state, {
        discountCode: {
            enabled:{
                $set: newChecked
            }
        }
    }))}

    const handleExcludeProductChange = (newChecked) => { setState(update(state,{isExcludeProduct:{$set: newChecked}})) }
    const handleExcludeOfferChange = (newChecked) => { setState(update(state,{isExcludeOffer:{$set: newChecked}})) }
    const handleOperatorChange = (andIndex, orIndex, value) => {
        if("hasAny" === value || "notAny" === value || "hasSubscription" === value || "hasNoSubscription" === value){
            setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{operator: {$set: value}, value: {$set: null}, product: {$set: null}}}}}}}}))
        }else{
            if("greaterThanString" === value){
                setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{operator: {$set: value}, value: {$set: '0.00'}}}}}}}}))
            }else{
                setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{operator: {$set: value}, value: {$set: null}}}}}}}}))
            }
        }
    }
    const handleSelectedFactChange = (andIndex, orIndex, value) => {
        if("cartProducts" === value){
            setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{fact: {$set: value}, operator: {$set: 'hasAny'}, value: {$set: null}, product: {$set: null}}}}}}}}))
        }else{
            if("cartTotal" === value){
                setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{fact: {$set: value}, operator: {$set: 'greaterThanString'}, value: {$set: '0.00'}, product: {$set: null}}}}}}}}))
            }else{
                if("cartProductQTY" === value){
                    setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{fact: {$set: value}, operator: {$set: 'isQTY'}, value: {$set: null}, product: {$set: null}}}}}}}}))
                }else{
                    if ("discountCode" === value) {
                        setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{fact: {$set: value}, operator: {$set: 'hasDiscountCode'}, value: {$set: ''}, product: {$set: null}}}}}}}}))
                    } else {
                        if("cartValue" === value){
                            setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{fact: {$set: value}, operator: {$set: 'greaterThanCartValue'}, value: {$set: null}, product: {$set: null}}}}}}}}))
                        }else{
                        setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{fact: {$set: value}, operator: {$set: 'hasCustomerTag'}, value: {$set: null}, product: {$set: null}}}}}}}}))
                        }
                    }
                }
            }
        }
    }
    const handleChange = (andIndex, orIndex, value, type) => {
        if("product" === type){
            setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{value: {$set: value? value.map( item => normalizeShopifyID(item.id)) : value}, product: {$set: value}}}}}}}}))
        }else{
            if("qtyProduct" === type){
                const currentValue = state.triggers.conditions.all[andIndex].any[orIndex].value
                const qty = currentValue && (currentValue.qty !== null) ? currentValue.qty : null
                setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{value: {$set: value? {qty:qty,product:value.map( item => normalizeShopifyID(item.id))} : value}, product: {$set: value}}}}}}}}))
            }else{
                if("qty" === type){
                    const currentValue = state.triggers.conditions.all[andIndex].any[orIndex].value
                    const product = currentValue && currentValue.product ? currentValue.product : null
                    setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{value: {$set: {qty:value,product:product} }}}}}}}}))
                }else{
                    if ("variants" === type) {
                      setState(
                        update(state, {
                          triggers: {
                            conditions: {
                              all: {
                                [andIndex]: {
                                  any: {
                                    [orIndex]: {
                                      value: {
                                        $set: value
                                          ? value[0].variants.map((item) =>
                                              normalizeShopifyID(item.id)
                                            )
                                          : value,
                                      },
                                      product: { $set: value },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        })
                      )
                    } else {
                        if ("discountCode" === type) {
                            setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{value: {$set: value}, updatedAt: {$set: new Date().toISOString()}}}}}}}}))
                        } else {
                            if("cartValue" === type){
                                const currentValue = state.triggers.conditions.all[andIndex].any[orIndex].value
                                const productIDs = currentValue && currentValue.productIDs ? currentValue.productIDs : null
                                const api = currentValue && currentValue.api ? currentValue.api : false
                                setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{value: {$set: {cartValue: value, api: api, productIDs: productIDs}}}}}}}}}))
                            }else{
                            setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{value: {$set: value}}}}}}}}))
                            }
                        }
                    }
                }
            }
        } 
    }
    const setCollectionProducts = (andIndex, orIndex, value, cartValue = false) => {
        let api = false
        for (const item of value.nodes) {
            if(100 === item.products.edges.length ){
                api = true
                break;
            }
        }
        if(api){
            setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{api: {$set: true}, value: {$set: value.nodes.map(node => {return {id: node.id}})}}}}}}}}))
        }else{
            setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{value: {$set: value.nodes.map(node => {return {id: node.id, products:node.products.edges.map(product => normalizeShopifyID(product.node.id))}})}}}}}}}}))
        }
        if(cartValue){
            const currentValue = state.triggers.conditions.all[andIndex].any[orIndex].value
            const cartValue = currentValue && (currentValue.cartValue !== null) ? currentValue.cartValue : null
            if(api){
                setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{value: {$set: {cartValue: cartValue, api: true, productIDs: value.nodes.map(node => {return {id: node.id}})}}}}}}}}}))
            }else{
                setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{value: {$set: {cartValue: cartValue, productIDs: value.nodes.map(node => {return {id: node.id, products:node.products.edges.map(product => normalizeShopifyID(product.node.id))}})}}}}}}}}}))
            }
        }
    }
    const setTagProducts = (andIndex, orIndex, tag, value) => {
        if(100 === value.products.edges.length){
            setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{api: {$set: true}, value: { $set: {tag: tag} }}}}}}}}))
        }else{
            if (state?.triggers?.conditions?.all && state.triggers.conditions.all.length && state.triggers.conditions.all[andIndex].any) {
                setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{value: { $set: {tag: tag, products: value.products.edges.map(product => normalizeShopifyID(product.node.id))} }}}}}}}}))
            }
        }
    }
    const setCustomerTag = (andIndex, orIndex, tag) => {
        setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{value: { $set: {tag: tag} }}}}}}}}))
    }
    const setMarket = (andIndex, orIndex, markets) => {
        setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{[orIndex]:{value: { $set: markets}}}}}}}}))
    }
    const addOrCondition = (andIndex) => {
        setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{$push: [{fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}]}}}}}}))
    }
    const deleteOrCondition = (andIndex, orIndex, length) => {
        if( length > 1 ){
            setState(update(state,{triggers:{conditions:{all:{[andIndex]:{any:{$splice: [[orIndex, 1]]}}}}}}))
        }else{
            setState(update(state,{triggers:{conditions:{all:{$splice: [[andIndex, 1]]}}}}))
        }
    }
    const addAndCondition = () => {
        setState(update(state,{triggers:{conditions:{all:{$push: [{
            any: [
                {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
            ]
        }]}}}}))
    }
    const deleteAndCondition = (index) => {
        setState(update(state,{triggers:{conditions:{all:{$splice: [[index, 1]]}}}}))
    }
    const operatorsOptions = [
        {label: 'Greater than', value: 'greaterThanString'},
        {label: 'Less than', value: 'lessThanString'}
    ]
    const productOperatorOptions = [
        {label: 'Has any', value: 'hasAny'},
        {label: 'Is not', value: 'notAny'},
        {label: 'In Collection', value: 'inCollection'},
        {label: 'Not In Collection', value: 'notInCollection'},
        {label: 'Has Tag', value: 'hasTag'},
        {label: 'Doesn’t have tag', value: 'hasNoTag'},
    ]
    if("Checkout" === state.offerType || ("Cart" === state.offerType && "drawer" === state.cartType)){
        productOperatorOptions.push(
            {label: 'Has Variants', value: 'hasVariants'},
            {label: 'Doesn’t have variants', value: 'hasNoVariants'}
        )
    }
    if('extension' === state.checkoutType || "customization" === type){
        productOperatorOptions.push(
            {label: 'Has subscription', value: 'hasSubscription'},
            {label: 'Doesn’t have subscription', value: 'hasNoSubscription'}
        )
    }
    let customerOperatorOptions = [
        {label: 'Has Tag', value: 'hasCustomerTag'},
        {label: 'Doesn’t have tag', value: 'hasNoCustomerTag'},
        // {label: 'Is in market', value: 'isInMarket'},
        // {label: 'Is not in market', value: 'isNotInMarket'},
    ]

    if("Post Purchase" === state.offerType  || "Checkout" === state.offerType || "customization" === type){
        customerOperatorOptions.push({label: 'Has Language', value: 'hasCustomerLanguage'})
    }
    if(("Checkout" === state.offerType && state.checkoutType && 'extension' === state.checkoutType) || "customization" === type){
        customerOperatorOptions.push(
          {label: 'Is in market', value: 'isInMarket'},
          {label: 'Is not in market', value: 'isNotInMarket'}
        )
    }

    if(customerOperatorOptionsValue){
        customerOperatorOptions = customerOperatorOptionsValue
    }
    let factsOptions = [
        {label: 'Cart total', value: 'cartTotal'},
        {label: 'Cart products', value: 'cartProducts'},
        {label: 'Cart product QTY', value: 'cartProductQTY'},
    ];
    if("Post Purchase" === state.offerType  || "Checkout" === state.offerType){
        factsOptions.push({label: 'Customer', value: 'customer'})
    }

    if ("checkout" === type && state.checkoutType && 'extension' === state.checkoutType) {
        factsOptions.push({label: 'Discount code', value: 'discountCode'},  {label: 'Cart value', value: 'cartValue'},)
    }

    const discountOperatorOptions = [
        {label: 'Is', value: 'hasDiscountCode'},
        {label: 'Is not', value: 'hasNoDiscountCode'}
    ]
    if(factsOptionsValue){
        factsOptions = factsOptionsValue
    }

    return (
        <div style={{marginTop:'10px'}}>
            <FormLayout>
            { state.triggers ?
                <Conditions
                    host={host}
                    token={token}
                    conditions={state.triggers.conditions}
                    swap={state.swap}
                    swapQuantity={state.swapQuantity}
                    autoAdd={state.autoAdd}
                    isExcludeProduct={state.isExcludeProduct}
                    isExcludeOffer={state.isExcludeOffer}
                    factsOptions={factsOptions} 
                    handleSelectedFactChange={handleSelectedFactChange} 
                    operatorsOptions={operatorsOptions}
                    productOperatorOptions={productOperatorOptions}
                    customerOperatorOptions={customerOperatorOptions}
                    discountOperatorOptions={discountOperatorOptions}
                    handleOperatorChange={handleOperatorChange} 
                    handleChange={handleChange}
                    addOrCondition={addOrCondition}
                    addAndCondition={addAndCondition}
                    deleteOrCondition={deleteOrCondition}
                    deleteAndCondition={deleteAndCondition}
                    productPickerShow={productPickerShow}
                    setProductPickerShow={setProductPickerShow}
                    setCollectionProducts={setCollectionProducts}
                    setTagProducts={setTagProducts}
                    setCustomerTag={setCustomerTag}
                    setMarket={setMarket}
                    currencySymbol={currencySymbol}
                    handleSwapChange={handleSwapChange}
                    handleSwapQuantityChange={handleSwapQuantityChange}
                    handleAutoAddChange={handleAutoAddChange}
                    handleExcludeProductChange={handleExcludeProductChange}
                    handleExcludeOfferChange={handleExcludeOfferChange}
                    shop={shop}
                    type={type}
                    checkoutType={state.checkoutType}
                    discountCode={state.discountCode}
                    handleDiscountCodeChange={handleDiscountCodeChange}
                    handleDiscountCodeStatusChange={handleDiscountCodeStatusChange}
                    offerType={state?.variantsTriggers?.type}
                    existingOffer={existingOffer}
                />
                :
                false
            }
            { !state.triggers || (state.triggers && state.triggers.conditions.all.length === 0)?
                <div 
                        style={{ 
                        marginTop: 50,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row-reverse',
                        justifyContent: 'flex-start',
                        }}
                    >
                <Button  onClick={() => addSmartRule()} variant="primary">
                    Add smart rule
                </Button>
                </div>
                :
                false
            }
            </FormLayout>
        </div>
    );
}

export default SmartRules;